import FingerprintJS from "@fingerprintjs/fingerprintjs";
import QRCode from "qrcode";

document.addEventListener("DOMContentLoaded", async () => {
    const deviceFingerprintId = await initializeFingerprintAndQRCode();
    console.log("fingerprint.js :: Remote Visitor ID: ", deviceFingerprintId);

    setInterval(async () => {
        const response = await axios.post('/check-user-device-link', {
            device_fingerprint_id: deviceFingerprintId,
        });

        if (response.data.logged_in) {
            window.location.href = '/';
        }
    }, 5000);


    const localDeviceIp = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip);

    await registerFingerprint(deviceFingerprintId, localDeviceIp);

    const loginForm = document.getElementById('login-form');
    if (loginForm) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'device_fingerprint_id';
        input.value = deviceFingerprintId;
        loginForm.appendChild(input);
    }

    Echo.channel(`device-logged.${deviceFingerprintId}`)
        .listen('DeviceLoggedEvent', (e) => {
            console.log("Device logged: ", e);

            axios.post('/api/authenticate-with-token', {
                token: e.temporary_token,
            })
                .then(response => {
                    if (response.data.authenticated) {
                        window.location.href = '/';
                    } else {
                        console.error('Falha ao autenticar o dispositivo.');
                    }
                })
                .catch(error => {
                    console.error('Erro ao autenticar o dispositivo:', error);
                });
        });

});

async function initializeFingerprintAndQRCode() {
    try {
        const visitorId = await generateFingerprint();

        updateQRCode(visitorId);
        displayLoginCode(visitorId);

        return visitorId;
    } catch (error) {
        console.error("Erro ao gerar o fingerprint ou o QRCode: ", error);
    }
}

async function generateFingerprint() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    return result.visitorId;
}

function updateQRCode(visitorId) {
    const qrcodeElement = document.getElementById("qrcodeCanvas");
    if (!qrcodeElement) return;

    const url = `${window.location.origin}/vid/${visitorId}`;
    QRCode.toCanvas(qrcodeElement, url, {
        width: 150,
        height: 150,
    });
}

function displayLoginCode(visitorId) {
    const lastSix = visitorId.slice(-6);
    const visitorIdElements = document.querySelectorAll(".visitorId > div");

    if (visitorIdElements.length !== 7) {
        console.error("Os elementos de exibição do código de login não estão completos.");
        return;
    }

    visitorIdElements.forEach((element, index) => {
        if (index !== 3) {
            element.textContent = lastSix[index > 3 ? index - 1 : index];
        }
    });
}

async function registerFingerprint(fingerprint, localIp) {
    try {
        const response = await axios.post('/register-fingerprint', {
            device_fingerprint_id: fingerprint,
            device_ip: localIp,
        });

    } catch (error) {
        console.error('Erro ao registrar o fingerprint:', error);
    }
}
